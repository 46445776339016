import ScrollAnimation from "react-animate-on-scroll";
import AboutCard from "../components/about/AboutCard";
import AboutContent from "../components/about/AboutContent";
import Members from "../components/about/Members";
import CTA from "../components/cta/CTA";
import Divider from "../components/divider/Divider";
import FeaturedNFT from "../components/featuredNFT/FeaturedNFT";
import Footer from "../components/footer/Footer";
import HeroLanding from "../components/hero/HeroLanding";
import ItemDetails from "../components/itemDetails/ItemDetails";
import Roadmap from "../components/roadmap/Roadmap";



export default function LandingPage() {
    return (
        <>
            <HeroLanding
                heading="FASHIONCHAIN"
                subHeading="the meta luxury of NFTs"
            />

            <Divider />

            <ScrollAnimation animateIn="fadeInUp" delay={400} animateOnce={true} >
                <AboutContent
                    textSectionOrder="10"
                    textSection={[
                        "<h2 class='h2 fw-bold mb-3'>What is fashionchain</h2>",
                        "<p class='fz-20'>Fashionchain will give you access to an <b style=color:white>exclusive collection of images selected from the top stylist archives in fashion hystory</b>, physical and digital artworks, collectibles and more with a real value.</p>",
                        "<p class='fz-20'>Faschionchain wants to generate value, by <b style=color:white>exclusivity and historicity</b>. The first Archive for luxury contents related to fashion and designers. From history of fashion and design till the contemporary content creator. Sketches, new concepts, special content like never published interviews to designers and much more.</p>"
                    ]}
                    imageOrder="20"
                    image="img/nft-img/2.jpg"
                />
            </ScrollAnimation>



            {/* <ScrollAnimation animateIn="fadeInUp" delay={400} animateOnce={true} >
            <CTA
                backgroundColor="primary" // try 'success', 'warning', 'danger', 'info' etc 
                text="Resources for getting started with Funto."
                buttonText="Get Started"
                buttonColor="warning"
                buttonURL="#"
                buttonIcon=""
            />
            </ScrollAnimation> */}

            <Divider />

            <ScrollAnimation animateIn="fadeInUp" delay={400} animateOnce={true} >
                <CTA
                    backgroundColor="primary" // try 'success', 'warning', 'danger', 'info' etc 
                    text="Why are we different"
                    buttonText=""
                    buttonColor=""
                    buttonURL=""
                    buttonIcon=""
                />
            </ScrollAnimation>

            <Divider />

            <AboutCard />

            <FeaturedNFT
                heading="Featured NFTs"
            />

            <ScrollAnimation animateIn="fadeInUp" delay={400} animateOnce={true} >
                <ItemDetails />
            </ScrollAnimation>

            <Divider />

            <ScrollAnimation animateIn="fadeInUp" delay={400} animateOnce={true} >
                <CTA
                    backgroundColor="primary" // try 'success', 'warning', 'danger', 'info' etc 
                    text="The first Archive for luxury contents related to fashion and designers."
                    buttonText="Explore"
                    buttonColor="warning"
                    buttonURL="/explore"
                    buttonIcon=""
                />
            </ScrollAnimation>

            <Divider />

            <ScrollAnimation animateIn="fadeInUp" delay={400} animateOnce={true} >
                <div className="container ">
                    <img className="card" src="img/core-img/dashboard.png" style={{ borderRadius: "1rem" }} />
                </div>
            </ScrollAnimation>

            <Divider />

            <ScrollAnimation animateIn="fadeInUp" delay={400} animateOnce={true} >
                <Roadmap />
            </ScrollAnimation>

            <Divider />

            <Members />

            <Divider />

            <Footer />
        </>
    )
}