import { Modal } from 'react-bootstrap'
import { useState } from 'react'


export default function TransactionModal({ transaction, action }) {

    const [show, setShow] = useState(true)
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title><h3 className="text-black">{action}</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className="text-grey">Transaction in Progress:<br /></span>
                    <a href={"https://www.etherscan.io/tx/" + transaction} target="_blank" rel="noreferrer" className="text-black">{transaction.substring(0,24)}...</a>
                </Modal.Body>
            </Modal>
        </>
    );

}