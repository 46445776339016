
import RoadMapData from '../../data/roadmap/roadmap-data.json'
import TinySlider from 'tiny-slider-react'

export default function Roadmap() {

    const sliderSettings = {
        items: 3,
        gutter: 24,
        slideBy: 1,
        autoplay: false,
        autoplayButtonOutput: false,
        autoplayTimeout: 5000,
        speed: 750,
        loop: true,
        nav: true,
        mouseDrag: true,
        controlsText: [('<i class="bi bi-arrow-left"></i>'), ('<i class="bi bi-arrow-right"></i>')],
        responsive: {
            320: {
                'items': 1,
            },
            480: {
                'items': 1.3,
            },
            576: {
                'items': 2,
            },
            768: {
                'items': 2.4,
            },
            992: {
                'items': 3,
            },
        }
    };

    const RoadMapCards = <TinySlider settings={sliderSettings}>
        {
            RoadMapData.map((elem, index) => (
                <div key={index} className="content">
                    <div className=''>
                        <h4 className='date'>{elem.date}</h4>
                        <h5 className='title fz-18'>{elem.title}</h5>
                        <p className='description'>{elem.description}</p>
                    </div>
                </div>))
        }
    </TinySlider>

    return (
        <div className='bg-dark'>
            <div className='container pt-120 pb-120'>
                <div className='section-heading'>
                    <h2>Road Map</h2>
                </div>
                <div className='timeline-container'>
                    {RoadMapCards}
                </div>
            </div>
        </div>
    )
}