import FooterNav from "./FooterNav";
import LeftWidget from "./LeftWidget";
import FooterWidget from "./Widget";

export default function Footer() {

    return(
        <footer className="footer-area pb-120 pt-120">
                        
            <div className="container">
                <div className="row">
                    {/* Footer Widget */}
                    <LeftWidget 
                        subText="The first fashion NFTs archive from stars  designers from the past  to the future generations." 
                        contactInfo="Email: info@fashionchain.finance"                        
                        socialVisibility="visible" // try 'visible' or 'hidden'
                        socialTitle="Join the community" 
                        socialLists={[
                            {
                                title: "Linkedin",
                                icon: "img/core-img/icons8-linkedin-fill.svg",
                                url: "https://www.linkedin.com/company/fashionchain-finance/"
                            },
                            {
                                title: "Twitter",
                                icon: "img/core-img/icons8-twitter-fill.svg",
                                url: "https://twitter.com/fashionchain"
                            }
                        ]} 
                        newsletterVisibility="hidden" // try 'visible' or 'hidden'
                    />

                    {/* Footer Widget */}
                    <div className="col-12 col-lg-7">
                        <div className="row g-4">

                            {/* Single Widget */}
                            <FooterWidget
                                widgetTitle="Explore" 
                                navList={[
                                    {
                                        text: "Collections",
                                        url: "/collections"
                                    },
                                ]}
                            />

                            
                            {/* Single Widget */}
                            <FooterWidget
                                widgetTitle="Company" 
                                navList={[
                                    {
                                        text: "Privacy",
                                        url: "/privacy"
                                    },
                                    {
                                        text: "Contact",
                                        url: "/contact"
                                    },
                                ]}
                            />

                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copywrite-wrapper d-lg-flex align-items-lg-center justify-content-lg-between">
                    
                    {/* Copywrite Text */}
                    <div className="copywrite-text text-center text-lg-start mb-3 mb-lg-0">
                        <p className="mb-0">Copyright 2022- Fashionchain LTD - UK #13798982</p>
                    </div>

                    {/* Footer Nav */}
                    <FooterNav 
                        navList={[
                            {
                                title: "Privacy Policy",
                                link: "/privacy"
                            },
                            {
                                title: "Terms & Conditions",
                                link: "/terms"
                            }
                        ]}
                    />
                    
                </div>
            </div>
        </footer>
    )
}