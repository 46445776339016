import { useState, useEffect, useRef, useContext } from "react"
import { Link, useParams } from "react-router-dom"
import axios from "axios"
import CountUp from 'react-countup'
import TrackVisibility from 'react-on-screen'

import { UserContext } from "../App"
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"
import Divider from "../components/divider/Divider"
import AuthorData from "../data/author/authors-data.json"

import $ from "jquery"
import { Spinner } from "react-bootstrap"
window.jQuery = window.$ = $;
require("jquery-nice-select")

const Author = () => {
    const userAddress = useParams().AUTHORUSERNAME;
    const loggedUser = useContext(UserContext)
    const authorUsername = "designing_world"
    const authorDetailsData = AuthorData.filter(item => item.username === authorUsername)
    const authorData = authorDetailsData[0];

    const [userData, setUserData] = useState({})
    const [userNfts, setUserNfts] = useState([])
    const [slicedNfts, setSlicedNfts] = useState([])
    const [loading, setLoading] = useState(true)

    const selectSortBy = useRef()
    const selectCatagories = useRef()

    useEffect(() => {
        $(selectSortBy.current).niceSelect()
        $(selectCatagories.current).niceSelect()
        getData()
        getNfts()
        console.log(loggedUser)
    }, [])

    async function getData() {
        const result = await axios.get(process.env.REACT_APP_SERVER_PORT + 'api/user/' + userAddress)
        const data = result.data
        if (data) {
            setUserData(data)
        }
    }

    async function getNfts() {
        const result = await axios.get(process.env.REACT_APP_SERVER_PORT + 'api/user/' + userAddress + '/nft')
        const nfts = result.data.ownedNfts
        if (nfts) {
            console.log(nfts)
            setUserNfts(nfts)
        }
        setLoading(false)
    }

    const [count, setCount] = useState(6)
    const [noMorePost, setNoMorePost] = useState(false)

    const handleLoadMore = () => {
        setCount(count + 6)
        if (count >= userNfts.length) {
            setNoMorePost(true)
        }
    }

    useEffect(() => {
        setSlicedNfts(userNfts.slice(0, count))
    }, [count, userNfts])

    const DiscoverNFTCards = slicedNfts.map((elem, index) => (
        <div key={index} className="col-12 col-sm-6 col-lg-4">
            <div className="nft-card card shadow-sm">
                <div className="card-body">
                    <Link to={`/collections/${elem.contract.address}/${parseInt(elem.id.tokenId, 16)}`}>
                        <div className="img-wrap">
                            {/* Image */}
                            <img src={elem.media[0].gateway} alt={elem.title} />
                        </div>
                    </Link>

                    {/* Others Info */}
                    <div className="row gx-2 align-items-center mt-3">
                        <div className="col-8">

                        </div>

                    </div>
                    {/* Meta Info */}
                    <div className="row gx-2 align-items-center mt-2">
                        <div className="name-info d-flex ">
                            <Link
                                className="name d-block hover-primary text-truncate"
                                to={`/collections/${elem.contract.address}/${parseInt(elem.id.tokenId, 16)}`}
                            >
                                {elem?.title}
                            </Link>

                        </div>

                        <div className="col-4">
                            <div className="price text-end">
                                <span className="fz-12 d-block">{elem.priceText}</span>
                                <h6 className="mb-0">{elem.currentPrice}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ))

    return (
        <>
            <Header />

            <div
                className="author-top-content bg-img bg-overlay"
                style={{ backgroundImage: userData?.banner ? `url(${userData.banner})` : `url(${process.env.PUBLIC_URL}/${authorData.bgImage})` }}
            >
                <div className="container">
                    <div className="card border-0 bg-transparent">
                        <div className="card-body p-4 p-sm-5">
                            <div className="row g-4 g-lg-5 align-items-center">
                                <div className="col-7 col-sm-6 col-md-5 col-lg-3">
                                    <div className="author-thumbnail">
                                        <img className="rounded" src={userData?.logo ? userData.logo : `${process.env.PUBLIC_URL}/${authorData.thumbnail}`} alt="" />
                                        {/* <i className="bi bi-patch-check-fill" /> */}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-10 col-md-7 col-lg-9">
                                    <div className="author-data">
                                        <h3 className="mb-2 author-name text-white">
                                            {userData?.name ? userData.name : userAddress}
                                        </h3>
                                        <div className="btn btn-minimal d-inline-block mb-3 text-white">
                                            {userData?.username && `@${userData.username}`}
                                        </div>
                                        <p className="mb-0">
                                            {userData?.shortDesc && userData.shortDesc}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="author-content-wrap mt-70">
                <div className="container">
                    <div className="row g-4 g-xl-5 justify-content-center">
                        <div className="row">
                            <div className="card author-sidebar-card shadow-sm">
                                <div className="card-body p-4 p-md-5 p-lg-4 p-xl-5">
                                    <h5 className="mb-3">About this author</h5>
                                    {userData?.authorDesc && userData.authorDesc}
                                    <div className="meta-data d-flex align-items-center mt-4">
                                        <h6 className="mb-0 text-center pe-3 me-3">
                                            <span className="counter">
                                                <TrackVisibility once>
                                                    {({ isVisible }) => (
                                                        <div>
                                                            {isVisible ? <CountUp duration={2} end={userNfts.length} /> : 0}
                                                        </div>
                                                    )}
                                                </TrackVisibility>
                                            </span> Items
                                        </h6>

                                        {/* <h6 className="mb-0 border-end text-center pe-3 me-3">
                                            <span className="counter">
                                                <TrackVisibility once>
                                                    {({ isVisible }) => (
                                                        <div>
                                                            {isVisible ? <CountUp duration={2} end={authorData.owners} /> : 0}
                                                        </div>
                                                    )}
                                                </TrackVisibility>
                                            </span> Owners
                                        </h6>

                                        <h6 className="mb-0 text-center">
                                            <span className="counter">
                                                <TrackVisibility once>
                                                    {({ isVisible }) => (
                                                        <div>
                                                            {isVisible ? <CountUp duration={2} end={authorData.followers} /> : 0}
                                                        </div>
                                                    )}
                                                </TrackVisibility>
                                            </span> Followers
                                        </h6> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row g-4 align-items-center justify-content-between">
                                <div className="col-12 col-sm-6">
                                    <select ref={selectSortBy} className="filter-select bg-gray w-100">
                                        <option value={1}>Recently Added</option>
                                        <option value={2}>Recently Sold</option>
                                        <option value={3}>Ending Soon</option>
                                    </select>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <select ref={selectCatagories} className="filter-select bg-gray w-100">
                                        <option value={1}>Art</option>
                                        <option value={2}>Cards</option>
                                        <option value={3}>Collectibles</option>
                                        <option value={4}>Domain</option>
                                        <option value={5}>Music</option>
                                    </select>
                                </div>
                            </div>

                            <div className="mb-4 d-block" />

                            <div className="row g-4">
                                {DiscoverNFTCards}

                                <div className="text-center mt-70">
                                    {
                                        loading ?
                                            <Spinner animation="border" variant="grey">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                            :
                                            <button
                                                className="btn btn-primary px-4 rounded-pill"
                                                onClick={() => handleLoadMore()}
                                                disabled={noMorePost ? "disabled" : null}
                                            >
                                                {
                                                    noMorePost ?
                                                        (<span className="d-flex align-items-center">
                                                            No Items Here
                                                            <i className="ms-2 bi bi-emoji-frown" />
                                                        </span>) :
                                                        (<span className="d-flex align-items-center">
                                                            View More Items
                                                            <i className="ms-2 bi bi-arrow-repeat" />
                                                        </span>)
                                                }
                                            </button>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Divider />

            <Footer />
        </>
    )
}

export default Author;