import { Link } from "react-router-dom";
import TinySlider from "tiny-slider-react";
import ScrollAnimation from "react-animate-on-scroll";

import FeaturedNFTData from "../../data/featuredNFT/featured-nft.json";

export default function FeaturedNFT(props) {
    const { heading } = props;

    const featuredNFTSettings = {
        items: 3,
        gutter: 24,
        slideBy: 1,
        autoplay: false,
        autoplayButtonOutput: false,
        autoplayTimeout: 5000,
        speed: 750,
        loop: true,
        nav: false,
        mouseDrag: true,
        controlsText: [('<i class="bi bi-arrow-left"></i>'), ('<i class="bi bi-arrow-right"></i>')],
        responsive: {
            320: {
                items: 1,
                gutter: 0
            },
            480: {
                items: 1.5,
                gutter: 24
            },
            576: {
                items: 2,
                gutter: 24
            },
            992: {
                items: 3,
                gutter: 24
            },
            1200: {
                items: 3,
                gutter: 24
            }
        }
    };

    const FeaturedNFTCards = FeaturedNFTData.slice(0, 3).map((elem, index) => (
        <div key={index} >
            <Link to={elem.link}>
                <div className="nft-card card featured-card border-0 bg-gray">
                    <div className="img-wrap landing-nft">
                        {/* Image */}
                        <img src={`${process.env.PUBLIC_URL}/${elem.image}`} alt={elem.title} />

                        {/* Badge */}
                        {/* <div className={`badge bg-${elem.badgeColor} position-absolute section-${elem.badgeVisibility}`} >
                        <img src={`${process.env.PUBLIC_URL}/${elem.badgeIcon}`} alt={elem.badgeText} />
                        {elem.badgeText}
                    </div> */}

                        {/* Dropdown */}

                    </div>

                    <div className="card-body">


                        {/* Meta Info */}
                        <div className="row gx-2 align-items-center mt-2">
                            <div className="col-12">
                                <div className="name-info d-flex align-items-center">


                                    <div className="name-author">

                                        <h5
                                            className="name d-block dark text-truncate"
                                            to={elem.link}
                                        >
                                            {elem.title}
                                        </h5>

                                        <div
                                            className="text-black text-truncate"
                                            to={`${process.env.PUBLIC_URL}/author/${elem.authorName}`}
                                        >
                                            @{elem.authorName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    ))

    return (
        <div className="featured-nfts-wrap pt-120 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-9 col-lg-6">
                        <div className="section-heading">
                            <ScrollAnimation animateIn="fadeInUp" delay={400} animateOnce={true} >
                                <h2 className="mb-0">{heading}</h2>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Featured NFT's Slide*/}
                        <div className="featured-nfts-slide">
                            <ScrollAnimation animateIn="fadeInUp" delay={800} animateOnce={true} >
                                <TinySlider settings={featuredNFTSettings}>
                                    {FeaturedNFTCards}
                                </TinySlider>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}