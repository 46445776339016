import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import Header from "../components/header/Header";
import SearchFormMint from "../components/mint/SearchFormMint";

export default function ExploreMint() {

    return (
        <>
            <Header />

            <Breadcrumb
                breadcrumbTitle="Mint"
                breadcrumbNav={[
                    {
                        navText: "Home",
                        path: "/"
                    }
                ]}
            />

            <div className="container">

                <SearchFormMint/>
            </div>
        </>
    )
}