import ScrollAnimation from 'react-animate-on-scroll';
import MembersData from '../../data/about/members-data.json';

export default function Members() {
    return (
        <div className='container'>
            <div className='section-heading'>
                <h2>Team</h2>
            </div>
            <div className='row g-4 g-xl-5 align-items-center justify-content-between'>
                {
                    MembersData.map((elem, index) => (
                        <div className="col-12 col-sm-6 col-xl-4" key={index} style={{padding:"2px 2px 16px 16px"}}>
                            <ScrollAnimation animateIn={index % 2 === 0 ? "fadeInUp" : "fadeInDown"} delay={400 * (index + 1)} animateOnce={true} >
                                <div className="card about-card shadow-sm" style={{ border: "3px solid white" }}>
                                    <div className="card-body py-4">
                                        <img src={elem.image} alt="" className="rounded-circle mb-4" />
                                        <h4 className="mb-3">
                                            {elem.name}
                                        </h4>
                                        <p className="mb-3">
                                            {elem.title}
                                        </p>
                                        <a href={elem.link ? elem.link : "https://www.linkedin.com/"} target="_blank" rel="noreferrer">
                                            <img src='img/core-img/icons8-linkedin-fill.svg' />
                                        </a>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}