import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
//import NavDropDown from "./DropDown";
import FuntoNavbar from "./Nav";
import useStickyHeader from "./StickyHeader";
import ConnectWalletButton from "./ConnectWalletButton";

export default function Header() {

    let [check] = useState(true);
    const [open, setOpen] = useState(false);
    const sticky = useStickyHeader(10);
    const stickyClass = `${(sticky && check) ? 'sticky-on' : ''}`

    useEffect(() => {
        const updatePosition = () => {
            setOpen(false);
        }
        window.addEventListener("scroll", updatePosition);
        updatePosition();
        return () => window.removeEventListener("scroll", updatePosition);
    }, []);


    return (
        <header className={`header-area ${stickyClass}`} >
            <Navbar expand="lg">
                <Container>
                    {/* Navbar Brand */}
                    <Link className="navbar-brand" to="/">
                        <h3>fashionchain</h3>
                    </Link>

                    {/* Navbar Toggler */}
                    <Navbar.Toggle className="navbar-toggler" aria-controls="funtoNav" onClick={() => setOpen(prev => !prev)} />

                    {/* Navbar */}
                    <Navbar.Collapse id="funtoNav" in={open}>
                        {/* Navbar List */}
                        <FuntoNavbar />

                        {/* Header Meta */}
                        <div className="header-meta d-flex align-items-center ms-lg-auto">

                            {/* Search Form */}
                            {/* <SearchForm /> */}

                            {/* User Dropdown */}
                            {/* <NavDropDown
                                dropdownID="dropdownMenuButton1"
                                toggleIcon="bi-three-dots"
                                dropdownList={[
                                    {
                                        text: "Dashboard",
                                        url: "/dashboard",
                                        icon: "bi-speedometer2"
                                    },
                                    {
                                        text: "Collections",
                                        url: "/collections",
                                        icon: "bi-collection"
                                    },
                                    {
                                        text: "Notifications",
                                        url: "/notifications",
                                        icon: "bi-bell"
                                    },
                                    {
                                        text: "Settings",
                                        url: "/settings",
                                        icon: "bi-gear"
                                    }
                                ]}
                            /> */}

                            {/* Create New Button */}
                            <ConnectWalletButton />

                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}