import ScrollAnimation from "react-animate-on-scroll";

export default function CTA(props) {
    const { text, buttonText, buttonColor, buttonURL, buttonIcon } = props;

    return (
        <div className="container">
            <div className={`cta-text p-4 p-sm-5 bg-light`} >
                <ScrollAnimation animateIn="fadeInUp" delay={500} animateOnce={true} >
                    <div className="row align-items-center">
                        <div className="col-12 col-md-8 col-lg-9">
                            <h2 dangerouslySetInnerHTML={{ __html: text }} className="mb-3 fw-bold mb-md-0 dark"></h2>

                        </div>
                        {
                            buttonText &&
                            
                            <div className="col-12 col-md-4 col-lg-3">
                                <div className="text-md-end">

                                    <a className={`btn btn-${buttonColor} rounded-pill`} target="_blank" rel="noreferrer" href={buttonURL} style={{ border: "3px solid white" }}>
                                        <i className={buttonIcon} ></i>
                                        {buttonText}
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    )
}