import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';


import LiveAuctionData from "../../data/liveAuction/live-auction.json";

export default function ItemDetails() {

    const bidDetailsData = LiveAuctionData.filter(item => item.id === 1);
    const bidsdata = bidDetailsData[0];

    // const clockTime = ({ days, hours, minutes, seconds }) => {
    //     return (
    //         <div className="bid-ends">
    //             <div>
    //                 <span className="days">{days}</span>
    //                 <span>Days</span>
    //             </div>
    //             <div>
    //                 <span className="hours">{hours}</span>
    //                 <span>Hours</span>
    //             </div>
    //             <div>
    //                 <span className="minutes">{minutes}</span>
    //                 <span>Min</span>
    //             </div>
    //             <div>
    //                 <span className="seconds">{seconds}</span>
    //                 <span>Sec</span>
    //             </div>
    //         </div>
    //     )
    // }

    const [modalShow2, setModalShow2] = useState(false);
    const [modalShow3, setModalShow3] = useState(false);

    // const socialData = [
    //     {
    //         path: "#",
    //         icon: "img/core-img/icons8-facebook.svg"
    //     },
    //     {
    //         path: "#",
    //         icon: "img/core-img/icons8-twitter.svg"
    //     },
    //     {
    //         path: "#",
    //         icon: "img/core-img/icons8-instagram.svg"
    //     },
    //     {
    //         path: "#",
    //         icon: "img/core-img/icons8-slack.svg"
    //     },
    //     {
    //         path: "#",
    //         icon: "img/core-img/icons8-player.svg"
    //     }
    // ]

    // function ShareModal(props) {
    //     return (
    //         <Modal
    //             {...props}
    //             size="lg"
    //             aria-labelledby="shareModalLabel"
    //             centered
    //             className="share-modal"
    //         >
    //             <Modal.Body>
    //                 <h5 id="shareModalLabel" className="text-center mb-3">Share this item</h5>
    //                 <div className="d-flex align-items-center justify-content-center">
    //                     {socialData.map((item, index) => (
    //                         <a key={index} className="mx-2" href={item.path} >
    //                             <img src={`${process.env.PUBLIC_URL}/${item.icon}`} alt="Social" />
    //                         </a>
    //                     ))}
    //                 </div>
    //                 <button
    //                     onClick={props.onHide}
    //                     className="btn btn-close-style btn-danger btn-sm rounded-pill"
    //                     type="button"
    //                 >
    //                     <i className="bi bi-x-lg" />
    //                 </button>
    //             </Modal.Body>
    //         </Modal>
    //     );
    // }

    function CopyLink(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="CopyLinkLabel"
                centered
                className="share-modal"
            >
                <Modal.Body>
                    <h5 id="CopyLinkLabel" className="text-center mb-3">Copy item link</h5>
                    <p className="user-select-all mb-0 border border-2 p-3 rounded">
                        {window.location.href}
                    </p>

                    <button
                        onClick={props.onHide}
                        className="btn btn-close-style btn-danger btn-sm rounded-pill"
                        type="button"
                    >
                        <i className="bi bi-x-lg" />
                    </button>
                </Modal.Body>
            </Modal>
        );
    }

    function Report(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="ReportLabel"
                centered
                className="share-modal"
            >
                <Modal.Body>
                    <h5 id="ReportLabel" className="text-center mb-3">Why are you reporting?</h5>

                    <form>
                        <textarea className="form-control mb-3" id="reportText" name="reportMessage" placeholder="Write your complaint." />
                        <button className="btn btn-primary btn-sm rounded-pill w-100" type="submit">Report</button>
                    </form>

                    <button
                        onClick={props.onHide}
                        className="btn btn-close-style btn-danger btn-sm rounded-pill"
                        type="button"
                    >
                        <i className="bi bi-x-lg" />
                    </button>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            <div className="item-details-wrap">
                <div className="container">
                    <div className="section-heading">
                        <h2>Showcased NFT</h2>
                    </div>
                    <div className="row g-4 g-lg-5 align-items-center justify-content-center">
                        {/* Item Thumbnail */}
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="item-big-thumb">
                                <Zoom
                                    overlayBgColorStart="rgba(0, 0, 0, 0)"
                                    overlayBgColorEnd="rgba(0, 0, 0, 0.95)"
                                    transitionDuration={400}
                                >
                                    <img src={`${process.env.PUBLIC_URL}/${bidsdata.image}`} alt={bidsdata.title} />
                                </Zoom>
                            </div>
                        </div>

                        {/* Item Details Content */}
                        <div className="col-12 col-md-10 col-lg-6">
                            <div className="item-details-content mt-5 mt-lg-0">
                                {/* Share Modal */}
                                {/* <ShareModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                /> */}

                                {/* Copy Link */}
                                <CopyLink
                                    show={modalShow2}
                                    onHide={() => setModalShow2(false)}
                                />

                                {/* Report Modal */}
                                <Report
                                    show={modalShow3}
                                    onHide={() => setModalShow3(false)}
                                />

                                {/* Dropdown */}
                                <Dropdown className={`section-${bidsdata.dropdownVisibility} item-details-dd`} >
                                    <Dropdown.Toggle className="rounded-pill shadow-sm p-0" id={`itemDetailsID${bidsdata.id}`}>
                                        <i className="bi bi-three-dots-vertical" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu align="end" >
                                        {/* <Link className="dropdown-item" to="#" onClick={() => setModalShow(true)}>
                                            <i className="me-2 bi bi-share" />
                                            Share
                                        </Link> */}

                                        <Link className="dropdown-item" to="#" onClick={() => setModalShow2(true)}>
                                            <i className="me-2 bi bi-box-arrow-up-right" />
                                            Copy Link
                                        </Link>

                                        <Link className="dropdown-item" to="#" onClick={() => setModalShow3(true)}>
                                            <i className="me-2 bi bi-flag" />
                                            Report
                                        </Link>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <div className="d-flex flex-wrap align-items-center">
                                    {/* Wishlist */}
                                    <button className="btn btn-danger btn-sm rounded-pill px-3 wishlist-btn" type="button">
                                        <i className="bi" />
                                        <span className="ms-1">
                                            {bidsdata.wishlistCount}
                                        </span>
                                    </button>

                                    {/* Badge */}
                                    <div className="badge border px-3 py-2 rounded-pill text-dark fz-14 d-inline-block ms-1 ms-sm-4">
                                        <i className="me-1 bi bi-eye" />
                                        {bidsdata.viewsNow} watching now
                                    </div>
                                </div>

                                {/* Title */}
                                <h2 className="my-3">
                                    {bidsdata.title}
                                </h2>

                                <div className="d-flex align-items-center mb-4">
                                    {/* Author Image */}
                                    {/* <div className="author-img position-relative me-3">
                                        <img
                                            className="shadow"
                                            src={`${process.env.PUBLIC_URL}/${bidsdata.authorAvater}`}
                                            alt={bidsdata.authorName}
                                        />
                                        <i className={`bi bi-check position-absolute bg-primary ${bidsdata.authorVerified}`} />
                                    </div> */}

                                    {/* Name & Author */}
                                    <div className="name-author">
                                        <span className="d-block fz-14">Created by</span>
                                        <Link
                                            className="author d-block fz-16 hover-primary text-truncate"
                                            to={`${process.env.PUBLIC_URL}/author/0x1b36a150f495783ac569b6f2e338bf604c7fa403`}
                                        >
                                            @{bidsdata.authorName}
                                        </Link>
                                    </div>
                                </div>

                                <div className="border-top w-75 mb-4" />

                                {/* Bids Countdown */}
                                {/* <p className="lh-1">Bid ending soon</p>
                                <Countdown date={bidsdata.bidEndsTime} intervalDelay={0} renderer={clockTime} />

                                <div className="mb-4"></div>

                                <div className="row align-items-end">
                                    <div className="col-6 col-sm-4">
                                        <p className="mb-2">{bidsdata.priceText}</p>
                                        <h5 className="text-center mb-0 border border-2 px-3 py-2 border-primary d-inline-block rounded w-100">
                                            {bidsdata.currentPrice}
                                        </h5>
                                    </div>
                                    <div className="col-6 col-sm-4 col-lg-5">
                                        <Link className={`btn btn-white-fill rounded-pill w-100 `} to={bidsdata.buttonInfo[0].url} >
                                            <img
                                                className="me-1"
                                                src={`${process.env.PUBLIC_URL}/${bidsdata.buttonInfo[0].icon}`}
                                                alt="" />
                                            {bidsdata.buttonInfo[0].text}
                                        </Link>
                                    </div>
                                </div> */}

                                {/* <div className="border-top w-75 my-4" /> */}

                                {/* Short Description */}
                                <div className="short-description">
                                    <h5>{bidsdata.shortDesc[0].heading}</h5>
                                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: bidsdata.shortDesc[0].body }} >
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}