import ScrollAnimation from "react-animate-on-scroll";
import AboutCardData from "../../data/about/about-card.json";

export default function AboutCard() {

    const AboutCards = AboutCardData.map((elem, index) => (
        <div className="col-12 col-sm-4 col-xl-4" key={index} >
            <ScrollAnimation animateIn={index % 2 === 0 ? "fadeInUp" : "fadeInDown"} delay={400 * (index + 1)} animateOnce={true} >
                <div className="card about-card shadow-sm">
                    <div className="card-body py-4">
                        <div className={`icon bg-heading`} >
                            <i className={`bi ${elem.icon}`} style={{color:"black"}} />
                        </div>
                        <h4 className="mb-3">
                            {elem.title}
                        </h4>
                        <p className="mb-0">
                            {elem.subTitle}
                        </p>
                    </div>
                </div>
            </ScrollAnimation>
        </div>

    ))

    return (
        <div className="bg-dark pt-120 pb-120">
            <div className="container">
                {/* <div className="section-heading">
                    <h2>Why are we different?</h2>
                </div> */}
                <div className="row g-4">
                    {AboutCards}
                </div>
            </div>
        </div>
    )
}