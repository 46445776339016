import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import Divider from "../divider/Divider";
import collectionDetail from "../../data/collection/collection-details.json";

export default function FirstDrop() {

    const address = useParams().COLLECTIONID

    const [collectionData, setCollectionData] = useState([])
    const [name, setName] = useState('')
    const [paragraph, setParagraph] = useState('')

    async function getData() {
        axios.get(`${process.env.REACT_APP_SERVER_PORT}api/collections/${address}/all`)
            .then(r => {
                setCollectionData(r.data)
            })
            .catch(e => console.log(e))
    }

    useEffect(() => {
        const collection = collectionDetail.filter((elem) => elem.address === address)[0]
        if (!collection) {
            window.location.href = "/404"
        }
        getData()
        setName(collection.name)
        setParagraph(collection.description)
    }, [])


    function NFTCards({ img, id, title }) {
        return (
            <div className="col-12 col-sm-6 col-xl-3">
                <Link to={`/collections/${address}/${id}`}>
                    <div className="nft-card card featured-card border-0 bg-gray">
                        <div className="img-wrap img-square" >
                            <img src={img} alt="nft-img" />
                        </div>

                        <div className="card-body">
                            <p className="text-black">{title}</p>
                            {/* Others Info */}
                            {/* <div className="row gx-2 align-items-center">
                        <div className="col-8">
                            <span className="d-block fz-12">
                                <i className={`bi ${elem.topLevelIcon}`} />
                                {elem.topLevelText}
                            </span>
                        </div>
                        <div className="col-4 text-end">
                            <button
                                className="wishlist-btn"
                                type="button"
                            >
                                <i className="bi" />
                            </button>
                        </div>
                    </div> */}

                            {/* <div className="row gx-2 align-items-center mt-2">
                            <div className="col-8">
                                <div className="name-info d-flex align-items-center">


                                </div>
                            </div>
                            <div className="col-4">
                                <div className="price text-end">
                                    <span className="fz-12 d-block">{elem.priceText}</span>
                                    <h6 className="mb-0 dark">{elem.currentPrice}</h6>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-2 align-items-center mt-3">
                            <div className="col-6">
                                <Link className={`btn btn-black-fill rounded-pill btn-sm`} to={elem.buttonGroup[0].leftButtonURL} >
                                    <i className={`bi ${elem.buttonGroup[0].leftButtonIcon}`} ></i>
                                    {elem.buttonGroup[0].leftButtonText}
                                </Link>
                            </div>
                            <div className="col-6 text-end">
                                <Link className={`btn btn-${elem.buttonGroup[1].rightButtonStyle} btn-sm hover-dark`} to={elem.buttonGroup[1].rightButtonURL} >
                                    <i className={`bi ${elem.buttonGroup[1].rightButtonIcon} me-1`} ></i>
                                    {elem.buttonGroup[1].rightButtonText}
                                </Link>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </Link>
            </div>
        )
    }

    return (
        <>
            <Header />

            <Breadcrumb
                breadcrumbTitle={name}
                breadcrumbNav={[
                    {
                        navText: "Home",
                        path: "/"
                    },
                    {
                        navText: "Collections",
                        path: "/collections"
                    }

                ]}
            />

            <Divider />

            <div className="container" style={{ border: "2px solid white", padding: "2rem", borderRadius: "2rem" }}>
                <p className="text-dark">{paragraph}</p>
            </div>

            <Divider />

            <div className="col-12 container">
                <div className="row g-4 ">


                    {
                        collectionData.map((nft, index) => {
                            const number = nft.tokenId
                            let imgUrl = nft.media[0].gateway
                            return (
                                <NFTCards key={index} id={number} img={imgUrl} title={nft.metadata.name} />
                            )
                        })
                    }
                </div>
            </div>

            <Divider />

            <Footer />
        </>
    )
}