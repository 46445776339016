import { useParams } from "react-router-dom";
import { useState } from "react";
import { abi } from "../data/abi/mint-abi";
import { useEffect } from "react";
import { ethers } from "ethers";
import Header from "../components/header/Header";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import Footer from "../components/footer/Footer";
import Divider from "../components/divider/Divider";

export default function Mint() {

    const address = useParams().MINTID
    const addressCut = address.substring(0, 6) + "..." + address.substring(address.length - 4, address.length)
    const [active, setActive] = useState(false)
    const [price, setPrice] = useState(0)
    const [isOwner, setIsOwner] = useState(false)

    useEffect(() => {
        checkSaleActive()
        getPrice()
        checkOwner()
    }, [address])

    async function checkSaleActive() {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const contract = new ethers.Contract(address, abi, provider)
            setActive(await contract.saleIsActive())
        } catch (e) {
            console.log(e);
        }
    }

    async function getPrice() {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const contract = new ethers.Contract(address, abi, provider)
            const gwei = await contract.PRICE()
            const ether = ethers.utils.formatEther(gwei)
            setPrice(ether)
        } catch (e) {
            console.log(e);
        }
    }

    async function setSale(setting) {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(address, abi, signer)
            await contract.setSaleIsActive(setting);
        } catch (e) {
            console.log(e);
        }
    }

    async function mint() {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(address, abi, signer)
            console.log(ethers.utils.parseEther(price))
            const tx = await contract.mint({ value: ethers.utils.parseEther(price) });
            console.log(tx)
        } catch (e) {
            console.log(e);
        }

    }

    async function checkOwner() {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(address, abi, signer)
            const owner = await contract.owner()
            const connectedUser = await getUser()
            owner.toLowerCase() === connectedUser.toLowerCase() ? setIsOwner(true) : setIsOwner(false)
        } catch (e) {
            console.log(e);
        }
    }

    async function getUser() {
        if (!window.ethereum) {
            return false;
        }
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            })
            if (addressArray && addressArray.length > 0) {
                return addressArray[0].toString();
            }
        } catch (error) {
            return false;
        }
    }

    async function withdraw() {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(address, abi, signer)
            const tx = await contract.withdraw();
            console.log(tx)
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <Header />

            <Breadcrumb
                breadcrumbTitle={addressCut}
                breadcrumbNav={[
                    {
                        navText: "Home",
                        path: "/"
                    },
                    {
                        navText: "Mint",
                        path: "/mint"
                    }
                ]}
            />

            <Divider />

            <h1>{address}</h1>
            <p>
                Sale is {active ? "active" : "not active"}
            </p>
            <h2>{price}</h2>
            {isOwner && <button className="btn btn-sm btn-white-fill rounded-pill " onClick={() => setSale(active ? false : true)}> Set Sale {active ? "Inactive" : "Active"} </button>}
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <div className="nft-card card shadow-sm">
                    <div className="card-body">
                        <div className="img-wrap">
                            {/* Image */}
                            <img src="/img/nft-img/1.jpg" alt="mint nft" />
                        </div>
                    </div>
                </div>
            </div>
            <button className="btn btn-sm btn-white-fill rounded-pill" onClick={() => mint()}> Mint </button>
            {isOwner && <button className="btn btn-sm btn-white-fill rounded-pill " onClick={() => withdraw()}> Withdraw </button>}

            <Divider />

            <Footer />
        </>
    );
}