import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import { ethers } from "ethers";

const SearchFormMint = () => {

    const [address, setAddress] = useState(false)
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        if (address) {
            setIsValid(ethers.utils.isAddress(address) ? true : false)
        }
    }, [address])

    function handleSubmit(e) {
        e.preventDefault();
        if (!isValid) {
            alert("Invalid address")
            return
        }

        window.location.href = `/mint/${address}`
        return
    }

    return (
        <div className="search-form position-relative d-flex align-items-center">
            <Form onSubmit={e => { handleSubmit(e) }} >
                <input className="form-control" type="text" placeholder="Search" onChange={e => { setAddress(e.target.value) }} />
                <button className="position-absolute" type="submit">
                    <i className="bi bi-search" />
                </button>
            </Form>
        </div>
    )
}

export default SearchFormMint;