import { Link } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../App"
import  Address  from "../Address"

export default function ConnectWalletButton() {
    const buttonColor = "btn-light"
    const buttonURL = "/connect-wallet"
    const buttonText = "Connect Your Wallet"
    const user = useContext(UserContext)

    return (
        <>
            {
                user ?
                    <Link className={`btn ${buttonColor} btn-sm rounded-pill`} to={`/author/${user}`} style={{ color: "white", border: "3px solid #76f9f9" }}>
                        <Address hex={user} />
                    </Link>
                    :
                    <Link className={`btn ${buttonColor} btn-sm rounded-pill`} to={buttonURL} style={{ color: "white", border: "3px solid #76f9f9" }}>
                        {buttonText}
                    </Link>
            }

        </>

    )
}
