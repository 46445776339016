import { useState, useEffect } from "react";
import { ethers } from "ethers";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
// import Tooltip from 'react-bootstrap/Tooltip';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Dropdown from 'react-bootstrap/Dropdown';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// import TinySlider from "tiny-slider-react";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import Divider from "../components/divider/Divider";
import LiveAuctionData from "../data/liveAuction/live-auction.json";
import Address from "../components/Address";
import SellNFT from "../components/collections/SellNft";
import { abi } from '../data/abi/marketplace-abi'
import TransactionModal from "../components/collections/TransactionModal";
import { Collapse, Spinner } from "react-bootstrap";

const LiveAuctionDetails = () => {
    const itemId = parseInt(useParams().BIDSID, 10);
    const address = useParams().COLLECTIONID;
    const bidDetailsData = LiveAuctionData.filter(item => item.id === 1);
    const bidsdata = bidDetailsData[0];
    const marketplaceAddress = process.env.REACT_APP_NFT_CONTRACT

    const [modalShow, setModalShow] = useState(false)
    const [modalShow2, setModalShow2] = useState(false)
    const [nftData, setNftData] = useState({})
    const [transactions, setTransactions] = useState([])
    const [connectedAccount, setConnectedAccount] = useState('')
    const [open, setOpen] = useState(false)
    const [price, setPrice] = useState('')
    const [transaction, setTransaction] = useState('')
    const [action, setAction] = useState('')
    const [noFunds, setNoFunds] = useState(true)

    const socialData = [
        {
            path: "#",
            icon: "img/core-img/icons8-facebook.svg"
        },
        {
            path: "#",
            icon: "img/core-img/icons8-twitter.svg"
        },
        {
            path: "#",
            icon: "img/core-img/icons8-instagram.svg"
        },
        {
            path: "#",
            icon: "img/core-img/icons8-slack.svg"
        },
        {
            path: "#",
            icon: "img/core-img/icons8-player.svg"
        }
    ]

    function ShareModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="shareModalLabel"
                centered
                className="share-modal"
            >
                <Modal.Body>
                    <h5 id="shareModalLabel" className="text-center mb-3">Share this item</h5>
                    <div className="d-flex align-items-center justify-content-center">
                        {socialData.map((item, index) => (
                            <a key={index} className="mx-2" href={item.path} >
                                <img src={`${process.env.PUBLIC_URL}/${item.icon}`} alt="Social" />
                            </a>
                        ))}
                    </div>
                    <button
                        onClick={props.onHide}
                        className="btn btn-close-style btn-danger btn-sm rounded-pill"
                        type="button"
                    >
                        <i className="bi bi-x-lg" />
                    </button>
                </Modal.Body>
            </Modal>
        );
    }

    function CopyLink(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="CopyLinkLabel"
                centered
                className="share-modal"
            >
                <Modal.Body>
                    <h5 id="CopyLinkLabel" className="text-center mb-3">Copy item link</h5>
                    <p className="user-select-all mb-0 border border-2 p-3 rounded">
                        {window.location.href}
                    </p>

                    <button
                        onClick={props.onHide}
                        className="btn btn-close-style btn-danger btn-sm rounded-pill"
                        type="button"
                    >
                        <i className="bi bi-x-lg" />
                    </button>
                </Modal.Body>
            </Modal>
        );
    }

    function PlaceHolderText({ length, row }) {
        const string = Array(length).fill('a').join('');
        if (row) {
            return (

                <div className="placeholder-glow">
                    {
                        Array(row).fill(string).map((item, index) => {
                            return (
                                <span className="placeholder" key={index}>{item}</span>
                            )
                        })
                    }
                </div>
            )
        }
        return (
            <div className="placeholder-glow">
                <span className="placeholder">{string}</span>
            </div>
        )
    }

    function MetadataCard({ trait, value }) {
        return (
            <div style={{ border: "2px solid black", borderRadius: "3rem", padding: "10px", margin: "10px" }}>
                <span className="text-black"><b>{trait}</b>: {value}</span>
            </div>
        )
    }

    const [key, setKey] = useState('details');

    // const relatedProjectSlides = {
    //     'items': 4,
    //     'gutter': 24,
    //     'slideBy': 1,
    //     'autoplay': true,
    //     'autoplayButtonOutput': false,
    //     'autoplayTimeout': 5000,
    //     'speed': 750,
    //     'loop': true,
    //     'mouseDrag': true,
    //     'controls': true,
    //     'nav': false,
    //     'controlsText': [('<i class="bi bi-arrow-left"></i>'), ('<i class="bi bi-arrow-right"></i>')],
    //     'responsive': {
    //         320: {
    //             'items': 1,
    //         },
    //         480: {
    //             'items': 1.3,
    //         },
    //         576: {
    //             'items': 2,
    //         },
    //         768: {
    //             'items': 2.4,
    //         },
    //         992: {
    //             'items': 3,
    //         },
    //         1200: {
    //             'items': 3.5,
    //         },
    //         1400: {
    //             'items': 4,
    //         }
    //     }
    // };

    const getData = async () => {
        axios.get(process.env.REACT_APP_SERVER_PORT + 'api/collections/' + address + '/' + itemId)
            .then(res => {
                setNftData(res.data)
            })
            .catch(err => { console.log(err) })
    }

    const getTransactions = async () => {
        axios.get(process.env.REACT_APP_SERVER_PORT + 'api/collections/' + address + '/' + itemId + '/transactions')
            .then(res => {
                setTransactions(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getPrice = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const contract = new ethers.Contract(marketplaceAddress, abi, provider)
            const result = await contract.getListing(address, itemId)
            setPrice(ethers.utils.formatEther(result.price))
        } catch (error) {
            setPrice(-1)
        }
    }

    async function getAccountConnected() {
        if (!window.ethereum) {
            setConnectedAccount('')
        }
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            })
            if (addressArray && addressArray.length > 0) {
                setConnectedAccount(addressArray[0].toString())
            }
        } catch (error) {
            setConnectedAccount('')
        }
    }

    async function checkFunds() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const weiBalance = await provider.getBalance(connectedAccount)
        const balance = ethers.utils.formatEther(weiBalance)
        if (price >= 0 && balance < price) {
            setNoFunds(true)
        } else {
            setNoFunds(false)
        }
    }

    const handleBuy = async (e) => {
        e.preventDefault()
        if (!(price > 0)) { return }
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const contract = new ethers.Contract(marketplaceAddress, abi, signer)
        const payableAmount = { value: ethers.utils.parseEther(price) }
        try {
            const result = await contract.buyFashionchainItem(address, itemId, payableAmount)
            setAction("Buying Item")
            setTransaction(result.hash)
            const receipt = await result.wait()
            if (receipt) {
                setAction("")
                setTransaction()
                location.reload()
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleCancel = async (e) => {
        e.preventDefault()
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const contract = new ethers.Contract(marketplaceAddress, abi, signer)
        const result = await contract.cancelListing(address, itemId)
        setAction("Deleting Listing")
        setTransaction(result.hash)
        const receipt = await result.wait()
        if (receipt) {
            setAction("")
            setTransaction()
            location.reload()
        }
    }


    useEffect(() => {
        getData()
        getTransactions()
        getAccountConnected()
        getPrice()
        checkFunds()
    }, [])

    // const LiveAuctionsCards = LiveAuctionData.map((elem, index) => (
    //     <div key={index}>
    //         <div className="nft-card card shadow-sm">
    //             <div className="card-body">
    //                 <div className="img-wrap">
    //                     {/* Image */}
    //                     <img src={`${process.env.PUBLIC_URL}/${elem.image}`} alt={elem.title} />

    //                     {/* Dropdown */}
    //                     <Dropdown className={`section-${elem.dropdownVisibility}`} >
    //                         <Dropdown.Toggle className="rounded-pill shadow-sm" id={`discoverID${elem.id}`}>
    //                             <i className="bi bi-three-dots-vertical" />
    //                         </Dropdown.Toggle>

    //                         <Dropdown.Menu align="end" >
    //                             {elem.dropdownInfo.map((item, index) => (
    //                                 <Link key={index} className="dropdown-item" to={item.dropdownItemURL} >
    //                                     <i className={`me-2 bi ${item.dropdownItemIcon}`} ></i>
    //                                     {item.dropdownItemText}
    //                                 </Link>
    //                             ))}
    //                         </Dropdown.Menu>
    //                     </Dropdown>

    //                 </div>

    //                 {/* Others Info */}
    //                 <div className="row gx-2 align-items-center mt-3">
    //                     <div className="col-8">
    //                         <span className="d-block fz-12">
    //                             <i className={`bi ${elem.topLevelInfo[0].icon} me-1`} />
    //                             {elem.topLevelInfo[0].text}
    //                         </span>
    //                     </div>
    //                     <div className="col-4 text-end">
    //                         <button
    //                             className="wishlist-btn"
    //                             type="button"
    //                         >
    //                             <i className="bi" />
    //                         </button>
    //                     </div>
    //                 </div>

    //                 {/* Meta Info */}
    //                 <div className="row gx-2 align-items-center mt-2">
    //                     <div className="col-8">
    //                         <div className="name-info d-flex align-items-center">
    //                             <div className="author-img position-relative">
    //                             </div>

    //                             <div className="name-author">
    //                                 <OverlayTrigger placement="top"
    //                                     delay={{ show: 250, hide: 400 }}
    //                                     overlay={
    //                                         <Tooltip id={`liveAuctionNFT${elem.id}`}>
    //                                             {elem.title}
    //                                         </Tooltip>
    //                                     }
    //                                 >
    //                                     <Link className="name d-block hover-primary text-truncate" to={`${process.env.PUBLIC_URL}/live-bid/${elem.id}`} >
    //                                         {elem.title}
    //                                     </Link>
    //                                 </OverlayTrigger>
    //                                 <Link
    //                                     className="author d-block fz-12 hover-primary text-truncate"
    //                                     to={`${process.env.PUBLIC_URL}/author/${elem.authorName}`}
    //                                 >
    //                                     @{elem.authorName}
    //                                 </Link>
    //                             </div>
    //                         </div>
    //                     </div>

    //                     <div className="col-4">
    //                         <div className="price text-end">
    //                             <span className="fz-12 d-block">{elem.priceText}</span>
    //                             <h6 className="mb-0">{elem.currentPrice}</h6>
    //                         </div>
    //                     </div>

    //                     <div className="col-12">
    //                         <Link
    //                             className={`btn btn-${elem.buttonInfo[0].style} rounded-pill btn-sm mt-3 w-100`}
    //                             to={elem.buttonInfo[0].url}
    //                         >
    //                             <i className={`bi ${elem.buttonInfo[0].icon} me-1`} ></i>
    //                             {elem.buttonInfo[0].text}
    //                         </Link>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // ))

    return (
        <>
            <Header />

            <Breadcrumb
                breadcrumbTitle="Details"
                breadcrumbNav={[
                    {
                        navText: "Home",
                        path: "/"
                    },
                    {
                        navText: <Address hex={address} />,
                        path: `/collections/${address}`
                    }
                ]}
            />

            <Divider />

            {/* Item Details */}
            <div className="item-details-wrap">
                <div className="container">
                    <div className="row g-4 g-lg-5 align-items-center justify-content-center">
                        {/* Item Thumbnail */}
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="item-big-thumb">
                                <Zoom
                                    overlayBgColorStart="rgba(0, 0, 0, 0)"
                                    overlayBgColorEnd="rgba(0, 0, 0, 0.95)"
                                    transitionDuration={400}
                                >
                                    {
                                        nftData.media != undefined ?
                                            <img src={nftData.media[0].gateway} alt="nft image" />
                                            :
                                            <img src={`${process.env.PUBLIC_URL}/img/bg-img/placeholder.png`} alt="placeholder" className="placeholder" />
                                    }
                                </Zoom>
                            </div>
                        </div>

                        {/* Item Details Content */}
                        <div className="col-12 col-md-10 col-lg-6">
                            <div className="item-details-content mt-5 mt-lg-0">
                                {/* Share Modal */}
                                <ShareModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                />

                                {/* Copy Link */}
                                <CopyLink
                                    show={modalShow2}
                                    onHide={() => setModalShow2(false)}
                                />


                                {/* Dropdown */}
                                <Dropdown className={`section-${bidsdata.dropdownVisibility} item-details-dd`} >
                                    <Dropdown.Toggle className="rounded-pill shadow-sm p-0" id={`itemDetailsID${bidsdata.id}`}>
                                        <i className="bi bi-three-dots-vertical" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu align="end" >
                                        <Link className="dropdown-item" to="#" onClick={() => setModalShow(true)}>
                                            <i className="me-2 bi bi-share" />
                                            Share
                                        </Link>

                                        <Link className="dropdown-item" to="#" onClick={() => setModalShow2(true)}>
                                            <i className="me-2 bi bi-box-arrow-up-right" />
                                            Copy Link
                                        </Link>


                                    </Dropdown.Menu>
                                </Dropdown>

                                {/* <div className="d-flex flex-wrap align-items-center">

                                    <button className="btn btn-danger btn-sm rounded-pill px-3 wishlist-btn" type="button">
                                        <i className="bi" />
                                        <span className="ms-1">
                                            {bidsdata.wishlistCount}
                                        </span>
                                    </button>

                                    <div className="badge border px-3 py-2 rounded-pill text-dark fz-14 d-inline-block ms-1 ms-sm-4">
                                        <i className="me-1 bi bi-eye" />
                                        {bidsdata.viewsNow} watching now
                                    </div>
                                </div> */}

                                {/* Title */}
                                <h2 className="my-3">
                                    {
                                        nftData.title != undefined ?
                                            nftData.title
                                            :
                                            <PlaceHolderText length={20} />
                                    }
                                </h2>

                                <div className="d-flex align-items-center mb-4">
                                    {/* Author Image */}


                                    {/* Name & Author */}
                                    <div className="name-author">
                                        <span className="d-block fz-14">Owened by</span>
                                        <Link
                                            className="author d-block fz-16 hover-primary text-truncate"
                                            to={`${process.env.PUBLIC_URL}/author/${nftData.owner}`}
                                        >
                                            {
                                                nftData.owner ?
                                                    nftData.owner === connectedAccount ?
                                                        <span>You</span>
                                                        :
                                                        <Address hex={nftData.owner} toName={true} />
                                                    :
                                                    <PlaceHolderText length={15} />

                                            }
                                        </Link>
                                    </div>
                                </div>

                                <div className="border-top w-75 mb-4" />
                                {nftData.owner === connectedAccount ?
                                    price > 0 ?
                                        <div className="row align-items-end">
                                            <div className="col-6 col-sm-4">
                                                <p className="mb-2">Price</p>
                                                <h5 className="text-center mb-0 border border-2 px-3 py-2 border-primary d-inline-block rounded text-white w-100">
                                                    {price} ETH
                                                </h5>
                                            </div>
                                            <div className="col-6 col-sm-4">
                                                <button className="rounded-pill w-100 btn btn-white-fill" onClick={() => setOpen(prev => !prev)}>{open ? "Cancel" : "Edit price"}</button>
                                            </div>
                                            <div className="col-6 col-sm-4">
                                                <button className="rounded-pill w-100 btn btn-white-fill" onClick={handleCancel}>Unlist</button>
                                            </div>
                                            <Collapse in={open}>
                                                <div className="row">
                                                    <SellNFT nftAddress={address} tokenId={itemId} price={price} sellerAddress={connectedAccount} />
                                                </div>
                                            </Collapse>
                                        </div>
                                        :
                                        <>
                                            <div className="col-6 col-sm-4">
                                                <button className="rounded-pill w-100 btn btn-white-fill" onClick={() => setOpen(prev => !prev)}>{open ? "Cancel" : "Sell"}</button>
                                            </div>
                                            <Collapse in={open}>
                                                <div className="row">
                                                    <SellNFT nftAddress={address} tokenId={itemId} price={price} sellerAddress={connectedAccount} />
                                                </div>
                                            </Collapse>
                                        </>
                                    :
                                    <>
                                        {
                                            price > 0 ?
                                                <div className="row align-items-end">
                                                    <div className="col-6 col-sm-4">
                                                        <p className="mb-2">Price</p>
                                                        <h5 className="text-center mb-0 border border-2 px-3 py-2 border-primary d-inline-block rounded text-white w-100">
                                                            {price} ETH
                                                        </h5>
                                                    </div>
                                                    <div className="col-6 col-sm-5">
                                                        <button className="rounded-pill w-100 btn btn-white-fill" onClick={handleBuy} disabled={noFunds}>
                                                            {!transaction ?
                                                                noFunds ?
                                                                    "Not Enoguh Funds"
                                                                    :
                                                                    "Buy"
                                                                :
                                                                <Spinner as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true" />
                                                            }
                                                        </button>
                                                    </div>
                                                    {transaction && <TransactionModal transaction={transaction} action={action} />}
                                                </div>
                                                :
                                                price === -1 ?
                                                    <div>
                                                        <p>Please connect your wallet</p>
                                                    </div>
                                                    :
                                                    <div>
                                                        <p>Not available for sale</p>
                                                    </div>
                                        }
                                    </>
                                }
                                <div className="border-top w-75 my-4" />

                                {/* Short Description */}
                                <div className="short-description">
                                    <h5>Short Description</h5>
                                    {
                                        nftData.description != undefined ?
                                            nftData.description
                                            :
                                            <PlaceHolderText length={20} row={10} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-block w-100 mb-70" />

            {/* Tabs */}
            <div className={`funto-tab-area ${bidsdata.tabVisibility}`} >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tab--area bg-gray p-4 p-lg-5">
                                <Tabs
                                    id="itemDetailsTab"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3"
                                >
                                    <Tab eventKey="details" title="Metadata">
                                        <h3 className="text-black">Metadata</h3>
                                        <div style={{ display: "flex" }}>
                                            {
                                                nftData?.metadata && nftData.metadata.attributes.map((attribute, index) => {
                                                    return (
                                                        <div key={index} style={{ display: "flex" }}>
                                                            <MetadataCard trait={attribute.trait_type} value={attribute.value} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Tab>

                                    <Tab eventKey="activity" title="Activity">
                                        <div className="table-responsive border shadow-sm activity-table bg-white">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Event</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">From</th>
                                                        <th scope="col">To</th>
                                                        <th scope="col">Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transactions.map((tx, index) => (
                                                        <tr key={index} >
                                                            <th scope="row">
                                                                {tx.event}
                                                            </th>
                                                            <td>
                                                                {tx.secondData}
                                                            </td>
                                                            <td>
                                                                <Address hex={tx.from} />
                                                            </td>
                                                            <td>
                                                                <Address hex={tx.to} />
                                                            </td>
                                                            <td>
                                                                time
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Divider />

            {/* <div className="related-project-area">
                <div className="container">
                    <div className="section-heading">
                        <h2 className="mb-0">Related Projects</h2>
                    </div>

                    <div className="related-project-slide">
                        <TinySlider settings={relatedProjectSlides}>
                            {LiveAuctionsCards}
                        </TinySlider>
                    </div>
                </div>
            </div> */}

            <Divider />

            <Footer />
        </>
    )
}

export default LiveAuctionDetails;