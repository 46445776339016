import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { InputGroup, Spinner } from 'react-bootstrap'
import { ethers } from "ethers"
import { abi as abiNFT } from "../../data/abi/mint-abi"
import { abi as abiMarketplace } from "../../data/abi/marketplace-abi"

import TransactionModal from "./TransactionModal"

export default function SellNFT({ nftAddress, tokenId, price, sellerAddress }) {

    const address = process.env.REACT_APP_NFT_CONTRACT

    const [inputPrice, setInputPrice] = useState(price)
    const [accepted, setAccepted] = useState(false)
    const [validated, setValidated] = useState(false)
    const [transaction, setTransaction] = useState('')
    const [action, setAction] = useState('')



    async function isAlreadySigned() {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const contract = new ethers.Contract(nftAddress, abiNFT, provider)
            const result = await contract.isApprovedForAll(sellerAddress, address)
            if (result) {
                setAccepted(true)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleSell = async (e) => {
        e.preventDefault()
        if (!parseFloat(inputPrice) > 0) {
            setValidated(false)
            return
        }
        if (!(price > 0)) {
            try {
                const provider = new ethers.providers.Web3Provider(window.ethereum)
                const signer = provider.getSigner()
                const contract = new ethers.Contract(address, abiMarketplace, signer)
                const bigPrice = BigInt(inputPrice * 10e17)
                const result = await contract.listItem(nftAddress, tokenId, bigPrice)
                setTransaction(result.hash)
                setAction('Listing on Marketplace')
                const receipt = await result.wait()
                if (receipt) {
                    setTransaction()
                    location.reload()
                }
            } catch (e) {
                console.log(e)
            }
        } else {
            try {
                const provider = new ethers.providers.Web3Provider(window.ethereum)
                const signer = provider.getSigner()
                const contract = new ethers.Contract(address, abiMarketplace, signer)
                const bigPrice = BigInt(inputPrice * 10e17)
                const result = await contract.updateListing(nftAddress, tokenId, bigPrice)
                setTransaction(result.hash)
                setAction('Updating Listing')
                const receipt = await result.wait()
                if (receipt) {
                    setTransaction()
                    location.reload()
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    const handleTerms = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(nftAddress, abiNFT, signer)
            const result = await contract.setApprovalForAll(address, 1)
            setTransaction(result.hash)
            setAction(`Approving Marketplace's Contract`)
            const receipt = await result.wait()
            if (receipt) {
                setTransaction()
                setAccepted(true)
            }
        } catch (e) {
            console.log(e);
            setAccepted(false)
        }
    }


    useEffect(() => {
        isAlreadySigned()
    }, [])



    return (
        <Form noValidate validated={validated} onSubmit={handleSell} className="pt-4">
            <div className="row align-items-center">
                {/* Price */}
                <div className="col-12 col-md-6">
                    <Form.Group className="mb-4">
                        <Form.Label className="mb-2 fz-16">Asking Price</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                id="price"
                                type="text"
                                value={inputPrice}
                                onChange={e => setInputPrice(e.target.value)}
                                isInvalid={!(parseFloat(inputPrice) > 0)}
                                required
                            />
                            <InputGroup.Text>ETH</InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                                Amount must be greater than zero
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </div>

                {/* Agree with Terms */}
                <div className="col-12 col-md-8">
                    <Form.Check
                        className="mb-4 mb-md-0"
                        type="checkbox"
                        label="I agree to all terms & conditions."
                        checked={accepted}
                        disabled={accepted}
                        onChange={() => !accepted && handleTerms()}
                    />
                </div>
                {transaction && <TransactionModal transaction={transaction} action={action} />}
                {/* Submit Button */}
                <div className="col-12 col-md-4">
                    {
                        price > 0 ?
                            <button className="btn btn-white-fill rounded-pill w-100" type="submit" disabled={!accepted}>
                                {!transaction ?
                                    "Modify price"
                                    :
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                }
                            </button>
                            :
                            <button className="btn btn-white-fill rounded-pill w-100" type="submit" disabled={!accepted} >
                                {!transaction ?
                                    "List for Sale"
                                    :
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                }
                            </button>
                    }
                </div>
            </div>
        </Form>
    )
}