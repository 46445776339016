import axios from "axios";
import { useState, useEffect } from "react"

export default function Address({ hex, toName }) {

    const [username, setUsername] = useState("")

    const shortAddress = hex.substring(0, 5) + "..." + hex.substring(hex.length - 4);

    if (hex === undefined) {
        return <span></span>
    }

    useEffect(() => {
        if (toName) {
            getData()
        }
    }, [])

    async function getData() {
        const result = await axios.get(process.env.REACT_APP_SERVER_PORT + 'api/user/' + hex)
        const data = result.data
        if (data?.username) {
            setUsername(data.username)
        }
    }

    if (parseInt(hex, 16) === 0) {
        return <span>Null Address</span>
    }

    return (
        <>
            {
                username ? <span>{username}</span> : <span>{shortAddress}</span>
            }
        </>
    )
}