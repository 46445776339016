import ScrollAnimation from "react-animate-on-scroll";
import Header from "../header/Header";

const HeroLanding = (props) => {
    const { heading, subHeading } = props;

    return (
        <>
            <Header />
            <img src="img/nft-img/1.jpg" alt="home-img" id="homecta" />
            <div className="welcome-area" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-xl-8">
                            <div className="welcome-content text-center">
                                <ScrollAnimation animateIn="fadeInUp" delay={400} animateOnce={true} >
                                    <h2 dangerouslySetInnerHTML={{ __html: heading }} style={{fontWeight:"lighter"}}></h2>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="fadeInUp" delay={800} animateOnce={true} >
                                    <p className="mb-4" dangerouslySetInnerHTML={{ __html: subHeading }}></p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroLanding;